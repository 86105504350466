<template>
    <div id="chart" class="chart-container">
    </div>
</template>

<script>
export default {
    name: 'wildfiresChartComponent',
    props: ['workspace'],
}
</script>

<style scoped>
</style>